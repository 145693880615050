"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCell = void 0;
var constants_1 = require("../../shared/components/TemplateRender/Blocks/constants");
var nodeUtils_1 = require("../../shared/components/TemplateRender/utils/nodeUtils");
var TableConstants_1 = require("./TableConstants");
var TableCell = /** @class */ (function () {
    //#endregion
    function TableCell(ownerTable, rowKey, columnKey) {
        this.id = ownerTable.calcCellId(rowKey, columnKey);
        this.ownerTable = ownerTable;
        this.columnKey = columnKey;
        this.rowKey = rowKey;
        this.selected = false;
        this.rowSpan = 0;
        this.columnSpan = 0;
        this.merged = false;
        this.borderLeft = false;
        this.borderRight = false;
        this.borderTop = false;
        this.borderBottom = false;
        this.verticalAlign = constants_1.VerticalAligns.middle;
        this.content = '';
    }
    //#region Static methods
    TableCell.fromTableCellDto = function (ownerTable, ownerRow, cellDto) {
        var _a, _b, _c;
        var cell = new TableCell(ownerTable, ownerRow.key, cellDto.columnKey);
        Object.assign(cell, TableCell.getCellStyleSettings(cellDto));
        cell.rowSpan = (_a = cellDto.rowSpan) !== null && _a !== void 0 ? _a : 0;
        cell.columnSpan = (_b = cellDto.columnSpan) !== null && _b !== void 0 ? _b : 0;
        cell.merged = (_c = cellDto.merged) !== null && _c !== void 0 ? _c : false;
        cell.content = cellDto.content;
        return cell;
    };
    TableCell.getCellStyleSettings = function (cell) {
        var _a, _b, _c, _d, _e;
        return {
            borderBottom: (_a = cell === null || cell === void 0 ? void 0 : cell.borderBottom) !== null && _a !== void 0 ? _a : false,
            borderLeft: (_b = cell === null || cell === void 0 ? void 0 : cell.borderLeft) !== null && _b !== void 0 ? _b : false,
            borderRight: (_c = cell === null || cell === void 0 ? void 0 : cell.borderRight) !== null && _c !== void 0 ? _c : false,
            borderTop: (_d = cell === null || cell === void 0 ? void 0 : cell.borderTop) !== null && _d !== void 0 ? _d : false,
            verticalAlign: (_e = cell === null || cell === void 0 ? void 0 : cell.verticalAlign) !== null && _e !== void 0 ? _e : 'middle',
        };
    };
    TableCell.fromTableCellsDto = function (ownerTable, ownerRow, cellsDto) {
        var cells = cellsDto.map(function (cell) { return TableCell.fromTableCellDto(ownerTable, ownerRow, cell); });
        if (ownerTable.isEditMode()) {
            var technicalCell = ownerTable.createCell(ownerRow.key, TableConstants_1.TableConstants.TechnicalColumnKey, '');
            cells.unshift(technicalCell);
        }
        return cells;
    };
    TableCell.prototype.isSpan = function () {
        return this.rowSpan > 1 || this.columnSpan > 1;
    };
    TableCell.prototype.getPosition = function () {
        return this.ownerTable.getCellPosition(this.rowKey, this.columnKey);
    };
    TableCell.prototype.getRowSpanDelta = function () {
        if (this.rowSpan > 0)
            return this.rowSpan - 1;
        return 0;
    };
    TableCell.prototype.getColumnSpanDelta = function () {
        if (this.columnSpan > 0)
            return this.columnSpan - 1;
        return 0;
    };
    /**
     * Размер ячейки в "ячейках"
     */
    TableCell.prototype.getSizeSpan = function () {
        if (this.rowSpan > 0 && this.columnSpan > 0)
            return this.rowSpan * this.columnSpan;
        return 1 + this.getRowSpanDelta() + this.getColumnSpanDelta();
    };
    TableCell.prototype.setMerge = function () {
        this.merged = true;
        this.columnSpan = 0;
        this.rowSpan = 0;
        this.borderLeft = false;
        this.borderRight = false;
        this.borderTop = false;
        this.borderBottom = false;
        this.selected = false;
        this.content = '';
    };
    TableCell.prototype.setUnMerge = function () {
        this.merged = false;
        this.content = (0, nodeUtils_1.createNewParagraph)().outerHTML;
    };
    TableCell.prototype.copy = function (ownerTable) {
        var copyCell = new TableCell(ownerTable, this.rowKey, this.columnKey);
        copyCell.selected = this.selected;
        copyCell.rowSpan = this.rowSpan;
        copyCell.columnSpan = this.columnSpan;
        copyCell.merged = this.merged;
        copyCell.borderLeft = this.borderLeft;
        copyCell.borderTop = this.borderTop;
        copyCell.borderRight = this.borderRight;
        copyCell.borderBottom = this.borderBottom;
        copyCell.verticalAlign = this.verticalAlign;
        copyCell.content = this.content;
        return copyCell;
    };
    TableCell.prototype.toTableCellDto = function () {
        return {
            columnKey: this.columnKey,
            rowSpan: this.rowSpan === 0 ? undefined : this.rowSpan,
            columnSpan: this.columnSpan === 0 ? undefined : this.columnSpan,
            merged: !this.merged ? undefined : this.merged,
            borderLeft: !this.borderLeft ? undefined : this.borderLeft,
            borderTop: !this.borderTop ? undefined : this.borderTop,
            borderRight: !this.borderRight ? undefined : this.borderRight,
            borderBottom: !this.borderBottom ? undefined : this.borderBottom,
            verticalAlign: this.verticalAlign === 'middle' ? undefined : this.verticalAlign,
            content: this.content,
        };
    };
    TableCell.prototype.printHtml = function () {
        var width = this.ownerTable.getColumnWidth(this.columnKey);
        var result = "<td";
        if (this.columnSpan > 0)
            result += " colspan=\"".concat(this.columnSpan, "\"");
        if (this.rowSpan > 0)
            result += " rowspan=\"".concat(this.rowSpan, "\"");
        result += " style=\"word-wrap: break-word; width: ".concat(width, "mm; max-width: ").concat(width, "mm;");
        if (this.borderTop)
            result += " border-top-color: black; border-top-width: 1px; border-top-style: solid;";
        if (this.borderBottom)
            result += " border-bottom-color: black; border-bottom-width: 1px; border-bottom-style: solid;";
        if (this.borderLeft)
            result += " border-left-color: black; border-left-width: 1px; border-left-style: solid;";
        if (this.borderRight)
            result += " border-right-color: black; border-right-width: 1px; border-right-style: solid;";
        if (this.verticalAlign !== 'middle')
            result += " vertical-align: ".concat(this.verticalAlign, ";");
        result += '">';
        result += this.content;
        result += '</td>';
        return result;
    };
    TableCell.prototype.debugInfo = function () {
        var id = this.id.replaceAll(this.ownerTable.tableArgs.id + '_', '');
        return "R: ".concat(this.rowKey, ", C: ").concat(this.columnKey, ", id: ").concat(id);
    };
    return TableCell;
}());
exports.TableCell = TableCell;
