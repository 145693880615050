"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPhValueById = exports.getDocumentPhData = void 0;
var getDocumentPhData = function (placeholder) {
    // placeholderId - у ПХ в списке, id - у остальных ПХ
    var placeholderId;
    var placeholderIndex;
    if ('placeholderId' in placeholder) {
        placeholderId = placeholder.placeholderId;
        placeholderIndex = placeholder.placeholderIndex;
    }
    else {
        placeholderId = placeholder.id;
    }
    return {
        placeholderId: placeholderId,
        placeholderIndex: placeholderIndex,
    };
};
exports.getDocumentPhData = getDocumentPhData;
var getPhValueById = function (phId, phValues) {
    return phValues === null || phValues === void 0 ? void 0 : phValues.find(function (obj) { return obj.placeholder_id === phId; });
};
exports.getPhValueById = getPhValueById;
