"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhValuesUtils = void 0;
var TypeUtils_1 = require("./TypeUtils");
/**
 * Утилиты проверок valueContent
 */
var PhValuesUtils = /** @class */ (function () {
    function PhValuesUtils() {
    }
    /**
     * Возвращает значение ПХ
     * @param [phValue=] - значение ПХ выбранное/введенное пользователем
     */
    PhValuesUtils.getPlaceholderValue = function (phValue) {
        var _a, _b, _c;
        if (!phValue) {
            return '';
        }
        if (PhValuesUtils.isTextKeyValue(phValue.valueContent) || PhValuesUtils.isTextValue(phValue.valueContent)) {
            return (_b = (_a = phValue.valueContent) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        }
        // для обратной совместимости
        return (_c = phValue.value) !== null && _c !== void 0 ? _c : '';
    };
    /**
     * Возвращает значение ПХ в ПХ-списке
     * @param [phValue=] - значение ПХ выбранное/введенное пользователем
     * @param [rawIndex=]
     */
    PhValuesUtils.getTlPlaceholderValue = function (phValue, rawIndex) {
        var _a, _b, _c, _d;
        var index = Number(rawIndex);
        if (!phValue || isNaN(index)) {
            return '';
        }
        // ПХ с optionId
        if (PhValuesUtils.isTLKeyValue(phValue.valueContent)) {
            return (_b = (_a = phValue.valueContent.items[index]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        }
        // текстовые ПХ
        return (_d = (_c = phValue.values) === null || _c === void 0 ? void 0 : _c[index]) !== null && _d !== void 0 ? _d : '';
    };
    /**
     * Проверка является ли значение типом "Ключ/значение"
     * @param [valueContent=] - данные введенных пользователем значений
     */
    PhValuesUtils.isTextKeyValue = function (valueContent) {
        return (valueContent === null || valueContent === void 0 ? void 0 : valueContent.discriminator) === 'text-key-value';
    };
    /**
     * Проверка является ли значение типом "Текстовое значение"
     * @param [valueContent=] - данные введенных пользователем значений
     */
    PhValuesUtils.isTextValue = function (valueContent) {
        return (valueContent === null || valueContent === void 0 ? void 0 : valueContent.discriminator) === 'text';
    };
    /**
     * Проверка является ли значение типом "Список пар ключ/значение"
     * @param [valueContent=] - данные введенных пользователем значений
     */
    PhValuesUtils.isTLKeyValue = function (valueContent) {
        return (valueContent === null || valueContent === void 0 ? void 0 : valueContent.discriminator) === 'key-value-list';
    };
    /**
     * Проверка типа ПХ на соответствие radio или cascader
     */
    PhValuesUtils.isSelectPh = function (type) {
        return type === 'radio' || type === 'cascader';
    };
    /**
     * Проверка наличия у ПХ optionId
     */
    PhValuesUtils.isPhWithOptionId = function (type) {
        return type === 'radio' || type === 'cascader';
    };
    /**
     * Проверка выводится ли в панели редактирования данный тип ПХ из ПХ-списка
     */
    PhValuesUtils.isEditableTlPlaceholder = function (type) {
        return type === 'text' || type === 'directory' || type === 'radio' || type === 'cascader';
    };
    /**
     * Проверка разрешено ли использовать тип ПХ в ПХ-списках
     */
    PhValuesUtils.isTlPlaceholder = function (type) {
        return PhValuesUtils.isEditableTlPlaceholder(type) || type === 'UDMS';
    };
    /**
     * Проверка выводится ли в панели редактирования данный тип ПХ из ПХ-списка
     * Для обратной совместимости проверяется наличие атрибута placeholderType, так как placeholderField появился позже (ПХ с типом отличным от UDMS)
     */
    PhValuesUtils.getEditableTlPhDataset = function (node) {
        if (!TypeUtils_1.TypeUtils.isHtmlElement(node) || (!node.dataset.placeholderField && !node.dataset.placeholderType)) {
            return null;
        }
        return node.dataset;
    };
    /**
     * Возвращает id выбранного option
     * @param [phValue=] - значение ПХ выбранное/введенное пользователем
     * @param [index=] - в списке приходит массив значений
     */
    PhValuesUtils.getOptionId = function (phValue, index) {
        var _a, _b, _c;
        if (!phValue) {
            return '';
        }
        if (PhValuesUtils.isTLKeyValue(phValue.valueContent)) {
            return (_b = (_a = phValue.valueContent.items[Number(index)]) === null || _a === void 0 ? void 0 : _a.key) !== null && _b !== void 0 ? _b : '';
        }
        if (PhValuesUtils.isTextKeyValue(phValue.valueContent)) {
            return phValue.valueContent.key;
        }
        return (_c = phValue.optionId) !== null && _c !== void 0 ? _c : '';
    };
    /**
     * Возвращает value выбранного option
     * @param [phValue=] - значение ПХ выбранное/введенное пользователем
     * @param [index=] - в списке приходит массив значений
     */
    PhValuesUtils.getOptionValue = function (phValue, index) {
        var _a, _b, _c;
        if (!phValue) {
            return '';
        }
        // ПХ в ПХ-списке
        if (PhValuesUtils.isTLKeyValue(phValue.valueContent)) {
            return (_b = (_a = phValue.valueContent.items[Number(index)]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        }
        // ПХ не в ПХ-списке
        if (PhValuesUtils.isTextKeyValue(phValue.valueContent)) {
            return phValue.valueContent.value;
        }
        // для старой реализации
        return (_c = phValue.value) !== null && _c !== void 0 ? _c : '';
    };
    PhValuesUtils.getOptionById = function (id, options) {
        return options === null || options === void 0 ? void 0 : options.find(function (option) { return option.id === id; });
    };
    return PhValuesUtils;
}());
exports.PhValuesUtils = PhValuesUtils;
