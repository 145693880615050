"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageBreakValue = exports.placeholderTypesForCopySettings = exports.DEFAULT_BOUNDARY_PH_VALUES = exports.WITHOUT_PH = exports.WITHOUT_AUTONUMERIC = exports.ALERT_DESCRIPTIONS = exports.ERROR_TEXTS = void 0;
var BlockTypes_1 = require("./BlockTypes");
exports.ERROR_TEXTS = {
    boundaryInSelectedNodes: {
        message: 'Не удалось создать новое поле',
        description: 'В выделенном тексте уже присутствует "передаваемое поле"',
    },
    noSelectedText: {
        message: 'Не удалось создать новое поле',
        description: 'Чтобы создать "передаваемое поле", выделите фрагмент текста',
    },
};
exports.ALERT_DESCRIPTIONS = {
    COPY_BLOCK_DEPRECATE: 'Копирование и вставка блоков доступны только через верхнюю панель. Выделите элемент(не блок) для копирования и попробуйте еще раз.',
    CHOOSE_ELEMENT: 'Выделите элемент для копирования',
    PASTE_IN_BLOCK_DEPRECATE: 'Невозможно вставить элемент в этот блок',
    PASTE_DEPRECATE: 'Невозможно вставить элемент',
    COPY_BLOCK_FAIL: 'Не удалось вставить элемент',
    COPY_BOUNDARY_FAIL: 'Невозможно вставить фрагмент передаваемого поля в другое передаваемое поле',
    PASTE_PHS: 'Вставка полей из другого шаблона невозможна. Выделите новый из текушего.',
    HAS_ROOT_DIV: 'Скопированный контент невозможно вставить. Попробуйте еще раз.',
    COPY_SECTION_DEPRECATE: 'Копирование секции внутрь самой себя запрещено!',
};
exports.WITHOUT_AUTONUMERIC = [BlockTypes_1.blockTypes.table.type, BlockTypes_1.blockTypes.attachment.type, BlockTypes_1.blockTypes.list.type];
exports.WITHOUT_PH = [BlockTypes_1.blockTypes.table.type, BlockTypes_1.blockTypes.attachment.type, BlockTypes_1.blockTypes.section.type];
exports.DEFAULT_BOUNDARY_PH_VALUES = {
    customFormulaData: null,
    initial_title: 'Передаваемое поле',
    isRequired: false,
    isSent: true,
    subType: 'boundary',
    title: 'Передаваемое поле',
    type: 'exportContent',
};
exports.placeholderTypesForCopySettings = ['text', 'UDMS', 'cascader', 'radio'];
exports.pageBreakValue = {
    autonumeric: false,
    numeration_depth: 0,
    type: 'pageBreak',
};
