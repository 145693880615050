"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsUtils = void 0;
var Controls_1 = require("../../../models/Controls");
var TemplateStatus_1 = require("../TemplateStatus");
/**
 * Утилиты проверок прав
 */
var PermissionsUtils = /** @class */ (function () {
    function PermissionsUtils() {
    }
    /**
     * Проверка типа пришедших данных
     * @param info
     */
    PermissionsUtils.isTemplate = function (info) { return 'status' in info; };
    /**
     * Проверка возможности редактировать шаблон
     * @param info
     */
    PermissionsUtils.canEditeTemplate = function (info) {
        if (!PermissionsUtils.isTemplate(info) ||
            info.status === TemplateStatus_1.templateStatus.published.status ||
            info.status === TemplateStatus_1.templateStatus.archive.status) {
            return false;
        }
        return PermissionsUtils.isTemplateOwner(info) || PermissionsUtils.isTemplateEditor(info);
    };
    /**
     * Проверка есть ли у пользователя права владельца шаблона
     * @param info
     */
    PermissionsUtils.isTemplateOwner = function (info) {
        return (info.currentUserControls & Controls_1.Controls.Ownership) === Controls_1.Controls.Ownership;
    };
    /**
     * Проверка есть ли у пользователя права редактировать шаблон
     * @param info
     */
    PermissionsUtils.isTemplateEditor = function (info) {
        return (info.currentUserControls & Controls_1.Controls.Write) === Controls_1.Controls.Write;
    };
    /**
     * Проверка есть ли у пользователя права на сохранение шаблона
     * @param info
     */
    PermissionsUtils.canAutosaveTemplate = function (info) {
        return (PermissionsUtils.isTemplate(info) &&
            ((info === null || info === void 0 ? void 0 : info.status) === TemplateStatus_1.templateStatus.draft.status || (info === null || info === void 0 ? void 0 : info.status) === TemplateStatus_1.templateStatus.onEditing.status));
    };
    return PermissionsUtils;
}());
exports.PermissionsUtils = PermissionsUtils;
