"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTemplatedListPhsAttrs = void 0;
var common_1 = require("./common");
var constants_1 = require("../constants");
var setTemplatedListPhsAttrs = function (placeholders) {
    if (placeholders === void 0) { placeholders = []; }
    placeholders === null || placeholders === void 0 ? void 0 : placeholders.forEach(function (placeholder) {
        var isRequired = placeholder.isRequired, title = placeholder.title, type = placeholder.type, dataFormat = placeholder.dataFormat;
        var node = document.getElementById(common_1.AttrUtils.createPhId(placeholder.id));
        if (!node || !type) {
            return;
        }
        // атрибуты общие для всех ПХ
        node.setAttribute(constants_1.PH_DATASET.templatedListRequired, isRequired ? '1' : '0');
        node.setAttribute(constants_1.PH_DATASET.templatedListName, title);
        // удаление неиспользуемого атрибута из уже существующей разметки
        node.removeAttribute(constants_1.PH_DATASET.templatedListPh);
        // в старых версиях списков в атрибут type записывался только для directory и text, placeholderField только для directory
        if (!common_1.PhValuesUtils.isEditableTlPlaceholder(type)) {
            node.removeAttribute(constants_1.PH_DATASET.placeholderOptions);
            node.removeAttribute(constants_1.PH_DATASET.placeholderType);
            node.removeAttribute(constants_1.PH_DATASET.placeholderField);
            return;
        }
        node.setAttribute(constants_1.PH_DATASET.placeholderField, type);
        if (dataFormat) {
            node.setAttribute(constants_1.PH_DATASET.placeholderType, dataFormat);
        }
        else {
            node.removeAttribute(constants_1.PH_DATASET.placeholderType);
        }
        if (type === 'radio' || type === 'cascader') {
            node.setAttribute(constants_1.PH_DATASET.placeholderOptions, JSON.stringify(placeholder.options));
        }
        else {
            node.removeAttribute(constants_1.PH_DATASET.placeholderOptions);
        }
    });
};
exports.setTemplatedListPhsAttrs = setTemplatedListPhsAttrs;
