"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var nodeUtils_1 = require("../../shared/components/TemplateRender/utils/nodeUtils");
var common_1 = require("../../shared/utils/common");
var TableCell_1 = require("./TableCell");
var TableColumn_1 = require("./TableColumn");
var TableConstants_1 = require("./TableConstants");
var TableRow_1 = require("./TableRow");
var constants_1 = require("../../shared/components/TemplateRender/Blocks/constants");
var Table = /** @class */ (function () {
    function Table(tableArgs) {
        var _this = this;
        this.getBorderCellsStyle = function (border) {
            var _a;
            if (!((_a = _this.selectedCells) === null || _a === void 0 ? void 0 : _a.length)) {
                return false;
            }
            var firstBorderStyle = _this.selectedCells[0][border];
            for (var i = 0; i < _this.selectedCells.length; i++) {
                if (firstBorderStyle !== _this.selectedCells[i][border]) {
                    return false;
                }
            }
            return firstBorderStyle;
        };
        this.rows = [];
        this.columns = [];
        this.isTemplateMode = true;
        this.selectedCells = [];
        this.tableArgs = tableArgs;
    }
    Table.create = function (id, content, isContentEditable, isLib) {
        var table = new Table({ content: content, isContentEditable: isContentEditable, id: id, isLib: isLib });
        table.init();
        return table;
    };
    Table.createByDocument = function (content) {
        var table = new Table({ content: content, isContentEditable: false, id: '', isLib: false });
        table.isTemplateMode = false;
        table.init();
        return table;
    };
    Table.prototype.init = function () {
        var _a;
        if (this.tableArgs.content.table) {
            this.columns = TableColumn_1.TableColumn.fromTableColumnsDto(this, this.tableArgs.content.table.columns);
            this.rows = TableRow_1.TableRow.fromTableRowsDto(this, this.tableArgs.content.table.rows);
        }
        else {
            // Загрузка в старом формате данных, где
            // list[0] - массив информации о столбцах, ширина в пикселях
            // list[1] - массив объектов с ключами соответствующими индексами столбцов
            if (this.tableArgs.content.list && this.tableArgs.content.list.length) {
                this.columns = TableColumn_1.TableColumn.fromColumnsOld(this, this.tableArgs.content.list[0]);
                this.rows = TableRow_1.TableRow.fromRowsOld(this, this.tableArgs.content.list[0], this.tableArgs.content.list[1]);
                if ((_a = this.tableArgs.content.settings) === null || _a === void 0 ? void 0 : _a.bordered) {
                    this.setAllBorders();
                }
            }
            else {
                for (var i = 0; i < 2; i += 1) {
                    this.columns.push(this.createColumn(this.calcNextColumnKey(), this.calcColumnWidth(2)));
                }
                for (var i = 0; i < 2; i += 1) {
                    this.rows.push(this.createRow(this.calcNextRowKey()));
                }
            }
        }
        if (this.isEditMode()) {
            var technicalColumn = new TableColumn_1.TableColumn(this, TableConstants_1.TableConstants.TechnicalColumnKey);
            this.columns = __spreadArray([technicalColumn], this.columns, true);
            var technicalColumnRow = new TableRow_1.TableRow(this, TableConstants_1.TableConstants.TechnicalRowKey);
            technicalColumnRow.cells = this.createTechnicalCells();
            this.rows = __spreadArray([technicalColumnRow], this.rows, true);
        }
    };
    Table.prototype.isEditMode = function () {
        return (this.isTemplateMode &&
            (this.tableArgs.isLib == false || this.tableArgs.isLib == undefined) &&
            this.tableArgs.isContentEditable == true);
    };
    Table.prototype.refreshContents = function () {
        try {
            // обновляем содержимое ячеек таблицы
            var block_1 = this.getElementRootDom();
            this.rows.forEach(function (row) {
                if (row.key === TableConstants_1.TableConstants.TechnicalRowKey)
                    return row;
                var rowElement = common_1.GetUtils.getElementByTagAndAttribute(block_1, 'tr', 'data-row-key', row.key);
                if (rowElement) {
                    row.cells.forEach(function (cell) {
                        var cellElement = common_1.GetUtils.getElementByTagAndAttribute(rowElement, 'td', 'data-column-key', cell.columnKey);
                        if (cellElement) {
                            var cellContent = cellElement.firstChild;
                            if (cellContent) {
                                cell.content = cellContent.innerHTML;
                            }
                        }
                    });
                }
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    Table.prototype.getElementRootDom = function () {
        var wrapperId = "wrapper_".concat(this.tableArgs.id);
        var block = document.getElementById(wrapperId);
        return block;
    };
    //#region Работа со столбцами
    Table.prototype.calcNextColumnKey = function () {
        var colIndexes = this.columns
            .map(function (c) {
            if (c.key.indexOf(TableConstants_1.TableConstants.PrefixColumnKey) !== -1) {
                return Number(c.key.replace(TableConstants_1.TableConstants.PrefixColumnKey, ''));
            }
            return 0;
        })
            .sort();
        if (colIndexes.length == 0) {
            var nextKey = "".concat(TableConstants_1.TableConstants.PrefixColumnKey, "0");
            return nextKey;
        }
        else {
            var nextIndex = Math.max.apply(Math, colIndexes) + 1;
            var nextKey = "".concat(TableConstants_1.TableConstants.PrefixColumnKey).concat(nextIndex);
            return nextKey;
        }
    };
    Table.prototype.calcColumnWidth = function (columnCount) {
        var tableWidth = TableConstants_1.TableConstants.TableWidth - TableConstants_1.TableConstants.ColumnTechWidth;
        return columnCount ? tableWidth / columnCount : tableWidth;
    };
    Table.prototype.resetColumnsWidth = function () {
        var currentCols = this.columns.filter(function (c) { return c.key.indexOf(TableConstants_1.TableConstants.PrefixColumnKey) !== -1; });
        var div = TableConstants_1.TableConstants.ColumnMinWidth % currentCols.length;
        var increment = (TableConstants_1.TableConstants.ColumnMinWidth - div) / currentCols.length;
        currentCols.forEach(function (c) {
            c.width -= increment;
        });
        var firstNormalCol = currentCols.find(function (c) { return c.width >= TableConstants_1.TableConstants.ColumnMinWidth; });
        if (firstNormalCol)
            firstNormalCol.width -= div;
    };
    Table.prototype.createColumn = function (columnKey, width) {
        var column = new TableColumn_1.TableColumn(this, columnKey);
        column.width = width;
        return column;
    };
    Table.prototype.insertColumn = function (direction, currentColumnKey, copiedColumnKey) {
        var _this = this;
        var nextKey = this.calcNextColumnKey();
        var width = this.calcColumnWidth(this.columns.length);
        this.resetColumnsWidth();
        var newCol = this.createColumn(nextKey, width);
        var columnIndex = this.columns.findIndex(function (c) { return c.key === currentColumnKey; });
        var position = columnIndex + direction;
        if (columnIndex !== -1) {
            if (position <= 0) {
                this.columns.splice(position, 0, newCol);
            }
            else if (position > this.columns.length - 1) {
                this.columns.push(newCol);
            }
            else {
                this.columns.splice(position, 0, newCol);
            }
        }
        else {
            this.columns.push(newCol);
        }
        this.columns.forEach(function (c, i) {
            if (i)
                c.width = _this.calcColumnWidth(_this.columns.length - 1);
        });
        this.rows = this.rows.map(function (row) {
            _this.columns
                .map(function (c) { return c.key; })
                .forEach(function (columnKey) {
                if (!row.cells.find(function (x) { return x.columnKey === columnKey; })) {
                    var cell = _this.createCell(row.key, columnKey, (0, nodeUtils_1.createNewParagraph)().outerHTML);
                    var copiedCell = row.cells.find(function (v) { return v.columnKey === copiedColumnKey; });
                    Object.assign(cell, TableCell_1.TableCell.getCellStyleSettings(copiedCell));
                    if (columnIndex !== -1) {
                        if (position <= 0) {
                            row.cells.splice(position, 0, cell);
                        }
                        else if (position > row.cells.length - 1) {
                            row.cells.push(cell);
                        }
                        else {
                            row.cells.splice(position, 0, cell);
                        }
                    }
                    else {
                        row.cells.push(cell);
                    }
                }
            });
            return row;
        });
        return newCol;
    };
    Table.prototype.removeColumn = function (columnKey) {
        var index = this.columns.findIndex(function (col) { return col.key === columnKey; });
        this.columns.splice(index, 1);
        var width = this.calcColumnWidth(this.columns.length - 1);
        this.columns.forEach(function (c, i) {
            if (i === 0)
                return;
            c.width = width;
        });
        this.rows.forEach(function (row) {
            var index = row.cells.findIndex(function (x) { return x.columnKey === columnKey; });
            row.cells.splice(index, 1);
        });
    };
    Table.prototype.moveColumn = function (direction, columnKey) {
        var index = this.columns.findIndex(function (col) { return col.key === columnKey; });
        var position = index + direction;
        if (position < 0 || position > this.columns.length - 1) {
            return;
        }
        var item = this.columns[position];
        this.columns[position] = this.columns[index];
        this.columns[index] = item;
        this.rows.forEach(function (r) {
            r.moveCell(index, position);
        });
    };
    Table.prototype.getColumnWidth = function (columnKey) {
        var column = this.columns.find(function (column) { return column.key === columnKey; });
        if (column) {
            return column.width;
        }
        return -1;
    };
    Table.prototype.getColumnWidthByIndex = function (columnIndex) {
        return this.columns[columnIndex].width;
    };
    Table.prototype.setColumnWidth = function (columnKey, columnWidth) {
        var columnIndex = this.columns.findIndex(function (column) { return column.key === columnKey; });
        if (columnIndex == -1)
            return;
        this.columns[columnIndex].width = columnWidth;
        // Считаем ширину всех столбцов
        var totalWidth = 0;
        for (var index = 0; index < this.columns.length; index++) {
            totalWidth += this.columns[index].width;
        }
        var coeffDiv = TableConstants_1.TableConstants.TableWidth / totalWidth;
        // Прямо пропорционально уменьшаем ширину следующих столбцов
        for (var index = columnIndex; index < this.columns.length; index++) {
            this.columns[index].width *= coeffDiv;
        }
    };
    //#endregion
    //#region Работа со строками
    Table.prototype.calcNextRowKey = function () {
        var rowIndexes = this.rows
            .map(function (c) {
            if (c.key.indexOf(TableConstants_1.TableConstants.PrefixRowKey) !== -1) {
                return Number(c.key.replace(TableConstants_1.TableConstants.PrefixRowKey, ''));
            }
            return 0;
        })
            .sort();
        if (rowIndexes.length == 0) {
            var nextKey = "".concat(TableConstants_1.TableConstants.PrefixRowKey, "0");
            return nextKey;
        }
        else {
            var nextIndex = Math.max.apply(Math, rowIndexes) + 1;
            var nextKey = "".concat(TableConstants_1.TableConstants.PrefixRowKey).concat(nextIndex);
            return nextKey;
        }
    };
    Table.prototype.createRow = function (rowKey) {
        var _this = this;
        var row = new TableRow_1.TableRow(this, rowKey);
        var cells = [];
        this.columns.forEach(function (column) {
            var cell = _this.createCell(rowKey, column.key, (0, nodeUtils_1.createNewParagraph)().outerHTML);
            cells.push(cell);
        });
        if (!cells.find(function (x) { return x.columnKey === TableConstants_1.TableConstants.TechnicalColumnKey; }) && this.isEditMode()) {
            var technicalCell = this.createCell(rowKey, TableConstants_1.TableConstants.TechnicalColumnKey, '');
            cells.unshift(technicalCell);
        }
        row.cells = cells;
        return row;
    };
    Table.prototype.insertRow = function (direction, currentRowKey) {
        var newRow = this.createRow(this.calcNextRowKey());
        var rowIndex = this.rows.findIndex(function (c) { return c.key === currentRowKey; });
        var position = rowIndex + direction;
        if (rowIndex !== -1) {
            if (position <= 0) {
                this.rows.splice(position, 0, newRow);
            }
            else if (position > this.rows.length - 1) {
                this.rows.push(newRow);
            }
            else {
                this.rows.splice(position, 0, newRow);
            }
        }
        else {
            this.rows.push(newRow);
        }
        return newRow;
    };
    Table.prototype.removeRow = function (rowKey) {
        var index = this.rows.findIndex(function (row) { return row.key === rowKey; });
        if (index != -1) {
            this.rows.splice(index, 1);
        }
        else {
            console.log("removeRow: rowKey <".concat(rowKey, "> not found"));
        }
    };
    Table.prototype.moveRow = function (direction, rowKey) {
        var index = this.rows.findIndex(function (row) { return row.key === rowKey; });
        var position = index + direction;
        if (position < 1 || position > this.rows.length - 1) {
            return;
        }
        var item = this.rows[position];
        this.rows[position] = this.rows[index];
        this.rows[index] = item;
    };
    Table.prototype.getRowHeight = function (rowKey) {
        var row = this.rows.find(function (row) { return row.key === rowKey; });
        if (row && row.height) {
            return row.height;
        }
        return undefined;
    };
    Table.prototype.getRowHeightText = function (rowKey) {
        var row = this.rows.find(function (row) { return row.key === rowKey; });
        if (row && row.height) {
            return "\u0412\u044B\u0441\u043E\u0442\u0430 ".concat(row.height, "\u043C\u043C");
        }
        return 'Высота по содер.';
    };
    Table.prototype.setRowHeight = function (rowKey, height) {
        var row = this.rows.find(function (row) { return row.key === rowKey; });
        if (row) {
            var value = Number(height);
            if (Number.isNaN(value)) {
                row.height = undefined;
            }
            else {
                if (value < TableConstants_1.TableConstants.RowMinHeight)
                    value = TableConstants_1.TableConstants.RowMinHeight;
                if (value > TableConstants_1.TableConstants.RowMaxHeight)
                    value = TableConstants_1.TableConstants.RowMaxHeight;
                row.height = value;
            }
        }
    };
    //#endregion
    //#region Работа с ячейками
    Table.prototype.calcCellId = function (rowKey, columnKey) {
        var colIndex = '';
        if (columnKey == TableConstants_1.TableConstants.TechnicalColumnKey) {
            colIndex = TableConstants_1.TableConstants.TechnicalColumnKey;
        }
        else {
            colIndex = columnKey.split('_')[1];
        }
        var rowIndex = '';
        if (rowKey == TableConstants_1.TableConstants.TechnicalRowKey) {
            rowIndex = TableConstants_1.TableConstants.TechnicalRowKey;
        }
        else {
            if (rowKey.includes('_'))
                rowIndex = rowKey.split('_')[1];
            else
                rowIndex = rowKey;
        }
        var cellId = "".concat(this.tableArgs.id, "_").concat(rowIndex, "_").concat(colIndex);
        return cellId;
    };
    Table.prototype.createCell = function (rowKey, columnKey, content) {
        var cell = new TableCell_1.TableCell(this, rowKey, columnKey);
        cell.content = content;
        return cell;
    };
    Table.prototype.createTechnicalCells = function () {
        var _this = this;
        var cells = [];
        this.columns.forEach(function (c) {
            var cell = _this.createCell(TableConstants_1.TableConstants.TechnicalRowKey, c.key, '');
            cells.push(cell);
        });
        return cells;
    };
    Table.prototype.getCellPosition = function (rowKey, columnKey) {
        var pos = {
            rowIndex: -1,
            columnIndex: -1,
            rowKey: rowKey,
            columnKey: columnKey,
        };
        var rowIndex = this.rows.findIndex(function (x) { return x.key === rowKey; });
        if (rowIndex >= 0 && rowIndex < this.rows.length) {
            pos.rowIndex = rowIndex;
            var row = this.rows[rowIndex];
            var columnIndex = row.cells.findIndex(function (x) { return x.columnKey === columnKey; });
            if (columnIndex >= 0 && columnIndex < row.cells.length) {
                pos.columnIndex = columnIndex;
                return pos;
            }
            else {
                return pos;
            }
        }
        else {
            return pos;
        }
    };
    Table.prototype.getCell = function (rowKey, columnKey) {
        var rowTable = this.rows.find(function (x) { return x.key === rowKey; });
        if (rowTable) {
            return rowTable.cells.find(function (x) { return x.columnKey === columnKey; });
        }
        return undefined;
    };
    Table.prototype.getCellByIndex = function (row, column) {
        var rowTable = this.rows[row];
        if (rowTable) {
            return rowTable.cells[column];
        }
        return undefined;
    };
    Table.prototype.getCellContentDom = function (rowKey, columnKey) {
        var result = [];
        var block = this.getElementRootDom();
        var rowDom = common_1.GetUtils.getElementByTagAndAttribute(block, 'tr', 'data-row-key', rowKey);
        if (rowDom) {
            var cellDom = common_1.GetUtils.getElementByTagAndAttribute(rowDom, 'td', 'data-column-key', columnKey);
            if (cellDom && cellDom.firstChild) {
                var div = cellDom.firstChild;
                for (var c = 0; c < div.childNodes.length; c++) {
                    var node = div.childNodes.item(c).cloneNode(true);
                    result.push(node);
                }
                return result;
            }
        }
        return result;
    };
    Table.prototype.setCellContentFromNode = function (rowKey, columnKey, content) {
        var rowTable = this.rows.find(function (x) { return x.key === rowKey; });
        if (rowTable) {
            var cell = rowTable.cells.find(function (x) { return x.columnKey === columnKey; });
            if (cell) {
                var div = document.createElement('div');
                for (var n = 0; n < content.length; n++) {
                    var node = content[n];
                    div.appendChild(node);
                }
                cell.content = div.innerHTML;
            }
        }
    };
    Table.prototype.setAllBorders = function () {
        this.rows.forEach(function (row) {
            row.setAllBorders();
        });
    };
    //#endregion
    //#region Работа с выделенными ячейками
    Table.prototype.checkSelectedCells = function () {
        return this.selectedCells.length > 0;
    };
    Table.prototype.addOrRemoveSelectedCell = function (cell) {
        var c = this.selectedCells.findIndex(function (x) { return x.id === cell.id; });
        if (c != -1) {
            this.selectedCells[c].selected = false;
            this.selectedCells.splice(c, 1);
        }
        else {
            cell.selected = true;
            this.selectedCells.push(cell);
        }
    };
    Table.prototype.addRangeSelectedCell = function (startPos, endPos) {
        var rowStart = startPos.rowIndex <= endPos.rowIndex ? startPos.rowIndex : endPos.rowIndex;
        var rowEnd = (startPos.rowIndex <= endPos.rowIndex ? endPos.rowIndex : startPos.rowIndex) + 1;
        var columnStart = startPos.columnIndex <= endPos.columnIndex ? startPos.columnIndex : endPos.columnIndex;
        var columnEnd = (startPos.columnIndex <= endPos.columnIndex ? endPos.columnIndex : startPos.columnIndex) + 1;
        for (var r = rowStart; r < rowEnd; r++) {
            var row = this.rows[r];
            for (var c = columnStart; c < columnEnd; c++) {
                var cell = row.cells[c];
                cell.selected = true;
                this.selectedCells.push(cell);
            }
        }
    };
    Table.prototype.clearSelectedCells = function () {
        this.selectedCells.forEach(function (c) { return (c.selected = false); });
    };
    Table.prototype.mergeSelectedCells = function () {
        var rowStart = -1;
        var rowEnd = -1;
        var columnStart = -1;
        var columnEnd = -1;
        var sortSelectedCells = this.selectedCells.sort(this.sortSelectedCellsByRow).sort(this.sortSelectedCellsByColumn);
        var firstCell = sortSelectedCells[0];
        sortSelectedCells.forEach(function (sc, index) {
            if (index) {
                firstCell.content += sc.content;
            }
            if (rowStart === -1) {
                rowStart = sc.getPosition().rowIndex;
            }
            else {
                var rowCurrentEnd = sc.getPosition().rowIndex + sc.getRowSpanDelta();
                if (rowCurrentEnd > rowEnd) {
                    rowEnd = rowCurrentEnd;
                }
            }
            if (columnStart === -1) {
                columnStart = sc.getPosition().columnIndex;
            }
            else {
                var columnCurrentEnd = sc.getPosition().columnIndex + sc.getColumnSpanDelta();
                if (columnCurrentEnd > columnEnd) {
                    columnEnd = columnCurrentEnd;
                }
            }
            if (sc.id !== firstCell.id) {
                sc.setMerge();
            }
        });
        var rowSpan = rowEnd - rowStart + 1;
        var columnSpan = columnEnd - columnStart + 1;
        if (rowSpan > 1)
            firstCell.rowSpan = rowSpan;
        if (columnSpan > 1)
            firstCell.columnSpan = columnSpan;
    };
    /**
     * Проверка на возможность объединение выбранных ячеек
     */
    Table.prototype.checkMergeSelected = function () {
        if (this.selectedCells.length == 0 || this.selectedCells.length == 1)
            return false;
        var rowMin = -1;
        var rowMax = -1;
        var columnMin = -1;
        var columnMax = -1;
        // Сортируем ячейки по строкам (по их индексам) по возрастанию
        var sortSelectedCellsByRow = this.selectedCells.sort(this.sortSelectedCellsByRow);
        // Первая ячейка с наименьшим индексом строки
        var firstCell = sortSelectedCellsByRow[0];
        // Последняя ячейка с наибольшим индексом строки
        var lastCell = sortSelectedCellsByRow[sortSelectedCellsByRow.length - 1];
        // Соответственно наименьший индекс строки
        rowMin = firstCell.getPosition().rowIndex;
        // Соответственно наибольший индекс строки с учетом возможного объединения данной ячейки по строкам
        rowMax = lastCell.getPosition().rowIndex + lastCell.getRowSpanDelta();
        // Теперь сортируем ячейки по столбцам (по их индексам) по возрастанию
        var sortSelectedCellsByColumn = this.selectedCells.sort(this.sortSelectedCellsByColumn);
        // Первая ячейка с наименьшим индексом столбца
        firstCell = sortSelectedCellsByColumn[0];
        // Последняя ячейка с наибольшим индексом столбца
        lastCell = sortSelectedCellsByColumn[sortSelectedCellsByColumn.length - 1];
        // Соответственно наименьший индекс столбца
        columnMin = firstCell.getPosition().columnIndex;
        // Соответственно наибольший индекс столбца с учетом возможного объединения данной ячейки по столбцам
        columnMax = lastCell.getPosition().columnIndex + lastCell.getColumnSpanDelta();
        var rowSpan = rowMax - rowMin + 1;
        var columnSpan = columnMax - columnMin + 1;
        var sizeRange = rowSpan * columnSpan;
        // Размер ячеек с учетом их возможного объединения
        var sizeCell = 0;
        for (var _i = 0, _a = this.selectedCells; _i < _a.length; _i++) {
            var cs = _a[_i];
            sizeCell += cs.getSizeSpan();
        }
        // Вот так можно проверить прямоугольность диапазона
        return sizeCell == sizeRange;
    };
    Table.prototype.checkOneCellMergeSelected = function () {
        return this.selectedCells.length == 1 && this.selectedCells[0].isSpan();
    };
    Table.prototype.unMergeSelectedCell = function () {
        var _a;
        if (!((_a = this.selectedCells) === null || _a === void 0 ? void 0 : _a.length)) {
            return false;
        }
        var mergeCell = this.selectedCells[0];
        var mergePos = mergeCell.getPosition();
        var content = mergeCell.content;
        var startRow = mergePos.rowIndex;
        var endRow = startRow + (mergeCell.rowSpan === 0 ? 1 : Number(mergeCell.rowSpan));
        var startColumn = mergePos.columnIndex;
        var endColumn = startColumn + ((mergeCell === null || mergeCell === void 0 ? void 0 : mergeCell.columnSpan) === 0 ? 1 : Number(mergeCell.columnSpan));
        for (var r = startRow; r < endRow; r++) {
            var row = this.rows[r];
            for (var c = startColumn; c < endColumn; c++) {
                var cell = row.cells[c];
                Object.assign(cell, TableCell_1.TableCell.getCellStyleSettings(mergeCell));
                cell.setUnMerge();
            }
        }
        mergeCell.rowSpan = 0;
        mergeCell.columnSpan = 0;
        mergeCell.content = content;
    };
    Table.prototype.setBorderSelectedCells = function (border, value) {
        for (var i = 0; i < this.selectedCells.length; i++) {
            this.selectedCells[i][border] = value;
        }
    };
    Table.prototype.setBorderAllCells = function (value) {
        this.rows.forEach(function (row) {
            if (row.key === TableConstants_1.TableConstants.TechnicalRowKey) {
                return;
            }
            row.cells.forEach(function (cell) {
                if (cell.columnKey === TableConstants_1.TableConstants.TechnicalColumnKey) {
                    return;
                }
                constants_1.CELL_BORDERS.forEach(function (border) {
                    cell[border] = value;
                });
            });
        });
    };
    Table.prototype.getVerticalAlignSelectedCells = function () {
        if (this.selectedCells.length == 0)
            return;
        var first = this.selectedCells[0].verticalAlign;
        for (var index = 0; index < this.selectedCells.length; index++) {
            if (first != this.selectedCells[index].verticalAlign) {
                return;
            }
        }
        return first;
    };
    Table.prototype.setVerticalAlignSelectedCells = function (value) {
        for (var index = 0; index < this.selectedCells.length; index++) {
            this.selectedCells[index].verticalAlign = value;
        }
    };
    Table.prototype.setVerticalAlignAllCells = function (value) {
        for (var index = 0; index < this.selectedCells.length; index++) {
            this.selectedCells[index].verticalAlign = value;
        }
        this.rows.forEach(function (row) {
            if (row.key === TableConstants_1.TableConstants.TechnicalRowKey) {
                return;
            }
            row.cells.forEach(function (cell) {
                if (cell.columnKey !== TableConstants_1.TableConstants.TechnicalColumnKey) {
                    cell.verticalAlign = value;
                }
            });
        });
    };
    Table.prototype.sortSelectedCellsByRow = function (a, b) {
        var pa = a.getPosition();
        var pb = b.getPosition();
        return pa.rowIndex - pb.rowIndex;
    };
    Table.prototype.sortSelectedCellsByColumn = function (a, b) {
        var pa = a.getPosition();
        var pb = b.getPosition();
        return pa.columnIndex - pb.columnIndex;
    };
    Table.prototype.copySelectedCells = function (copyRows) {
        if (this.selectedCells.length === 0)
            return [];
        var copyCells = [];
        copyRows.forEach(function (r) {
            copyCells.push.apply(copyCells, r.getSelectedCells());
        });
        return copyCells;
    };
    //#endregion
    Table.prototype.copy = function () {
        var copyTable = new Table(this.tableArgs);
        var copyColumns = this.columns.map(function (c) { return c.copy(copyTable); });
        var copyRows = this.rows.map(function (r) { return r.copy(copyTable); });
        copyTable.isTemplateMode = this.isTemplateMode;
        copyTable.columns = copyColumns;
        copyTable.rows = copyRows;
        copyTable.selectedCells = this.copySelectedCells(copyRows);
        return copyTable;
    };
    Table.prototype.toTableDto = function () {
        var tableDto = {
            columns: this.columns.filter(function (c) { return c.key != TableConstants_1.TableConstants.TechnicalColumnKey; }).map(function (c) { return c.toTableColumnDto(); }),
            rows: this.rows.filter(function (c) { return c.key != TableConstants_1.TableConstants.TechnicalRowKey; }).map(function (r) { return r.toTableRowDto(); }),
        };
        return tableDto;
    };
    Table.prototype.printHtml = function () {
        var result = '<table style="width: 100%; border-collapse: collapse;">';
        result += '<tbody>';
        for (var index = 0; index < this.rows.length; index++) {
            var row = this.rows[index];
            result += row.printHtml();
        }
        result += '</tbody>';
        result += '</table>';
        return result;
    };
    return Table;
}());
exports.Table = Table;
