"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.excludePhTypes = void 0;
var common_1 = require("../../../utils/common");
function excludePhTypes(placeholderTypes, aggregation, templateType) {
    var _a;
    // покажем только те опции которые не скрыты canShow
    var filteredPhTypes = (_a = placeholderTypes === null || placeholderTypes === void 0 ? void 0 : placeholderTypes.filter(function (phType) { return phType.canShow; })) !== null && _a !== void 0 ? _a : [];
    if (common_1.EntityUtils.isEntityTypeAuthority(templateType) || common_1.EntityUtils.isCourtDocumentEntity(templateType)) {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'fromTemplate'; });
    }
    if (aggregation.type !== 'attachment') {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'attachment'; });
    }
    if (!aggregation.subType || aggregation.subType !== 'boundary') {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'exportContent'; });
    }
    if (common_1.EntityUtils.isTemplatedList(templateType)) {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return common_1.PhValuesUtils.isTlPlaceholder(t.controlType); });
    }
    return filteredPhTypes;
}
exports.excludePhTypes = excludePhTypes;
