"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttrUtils = void 0;
var uuid_1 = require("uuid");
var constants_1 = require("../../constants");
var TypeUtils_1 = require("./TypeUtils");
/**
 *  Утилиты работы с аттрибутами нод
 */
var AttrUtils = /** @class */ (function () {
    function AttrUtils() {
    }
    /**
     * Установка аттрибутов id для ПП
     * @param element - нода ПП
     * @param [id=v4()] - id ПП
     */
    AttrUtils.setPlaceholderIdAttrs = function (element, id) {
        if (id === void 0) { id = (0, uuid_1.v4)(); }
        element.setAttribute('id', AttrUtils.createPhId(id));
        element.setAttribute('data-placeholder', id);
        return id;
    };
    /**
     * Исправление разметки ПП
     * @param element - нода ПП
     */
    AttrUtils.fixPlaceholderAttrs = function (element) {
        element.setAttribute('onclick', 'window.textEditor.onPlaceholderClick(this)');
    };
    /**
     * Создание нового id для разметки
     * @param value - основа нового id, например, id ПХ
     * @param [prefix = null] - префикс, например, для id нового ПХ - ph
     * @param [postfix = null] - постфикс, например, для id нового ПХ он не нужен
     */
    AttrUtils.generateId = function (value, prefix, postfix) {
        if (prefix === void 0) { prefix = null; }
        if (postfix === void 0) { postfix = null; }
        return [prefix, value, postfix].filter(function (v) { return v !== null; }).join('_');
    };
    /**
     * Создание нового ключа
     * @param value - основа нового ключа, например, id секции
     * @param postfix - постфикс, например, order секции
     */
    AttrUtils.generateKey = function (value, postfix) {
        return "".concat(value, "_").concat(postfix);
    };
    /**
     * Получение id из ноды и очистка от префиксов и постфиксов
     * @param node - нода, которая проходит проверку на принадлежность типу HTMLElement
     */
    AttrUtils.getElementId = function (node) {
        var _a;
        return TypeUtils_1.TypeUtils.isElement(node) ? (_a = AttrUtils.parseId(node.id)) !== null && _a !== void 0 ? _a : '' : '';
    };
    /**
     * Получение аттрибута из ноды по его названию
     * @param node - нода, которая проходит проверку на принадлежность типу HTMLElement
     * @param [attr=id] - имя аттрибута
     */
    AttrUtils.getElementAttr = function (node, attr) {
        var _a;
        if (attr === void 0) { attr = 'id'; }
        return TypeUtils_1.TypeUtils.isElement(node) ? (_a = node.getAttribute(attr)) !== null && _a !== void 0 ? _a : '' : '';
    };
    /**
     * Получение id ПП из ноды
     * @param node - нода, которая проходит проверку на принадлежность типу HTMLElement
     */
    AttrUtils.getBoundaryId = function (node) {
        return AttrUtils.getElementAttr(node, constants_1.BOUNDARY_ATTRS.id);
    };
    /**
     * Получение id ПХ из ноды
     * @param node - нода, которая проходит проверку на принадлежность типу HTMLElement
     */
    AttrUtils.getPlaceholderId = function (node) {
        return AttrUtils.getElementAttr(node, 'data-placeholder');
    };
    /**
     * Очистка id для разметки от префиксов и постфиксов
     * @param value - строка, которая может содержать вместе с id префикс и постфикс
     */
    AttrUtils.parseId = function (value) {
        if (typeof value !== 'string') {
            return '';
        }
        var parts = value.split('_');
        return parts.length > 1 ? parts[1] : parts[0];
    };
    /**
     * Создание id ПХ для разметки
     * @param id - id ПХ в БД
     */
    AttrUtils.createPhId = function (id) {
        return AttrUtils.generateId(id, 'ph');
    };
    /**
     * Создание id контейнера блока для разметки
     * @param id - id ПХ в БД
     */
    AttrUtils.createWrapperId = function (id) {
        return AttrUtils.generateId(id, 'wrapper');
    };
    /**
     * Создание id ПХ-приложения для разметки
     * @param id - id ПХ в БД
     */
    AttrUtils.createPhWrapAttachmentId = function (id) {
        return AttrUtils.generateId(id, 'ph_wrap');
    };
    /**
     * Создание id правого ограничителя ПП для разметки
     * @param id - id ПХ в БД
     */
    AttrUtils.createBoundaryRightPhId = function (id) {
        return AttrUtils.generateId(id, 'ph', 'right');
    };
    return AttrUtils;
}());
exports.AttrUtils = AttrUtils;
