"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextOptions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var uuid_1 = require("uuid");
var lodash_1 = require("lodash");
var antd_1 = require("antd");
var constants_1 = require("../../utils/constants");
var placeholderValidationUtils_1 = require("../../utils/placeholderValidationUtils");
var placeholderValueHelpers_1 = require("../../../DocumentsPage/utils/placeholderValueHelpers");
var TextArea = antd_1.Input.TextArea;
var Option = antd_1.Select.Option;
var TextOptions = function (props) {
    var aggregation = props.aggregation, aggregations = props.aggregations, placeholder = props.placeholder, _a = props.disabled, disabled = _a === void 0 ? false : _a, onOptionChanged = props.onOptionChanged, updateAggregations = props.updateAggregations, onAggregationChange = props.onAggregationChange, _b = props.externalParamsCatalogue, externalParamsCatalogue = _b === void 0 ? [] : _b;
    var _c = (0, react_1.useState)([]), links = _c[0], setLinks = _c[1];
    var _d = (0, react_1.useState)([]), options = _d[0], setOptions = _d[1];
    (0, react_1.useEffect)(function () {
        var external_params = aggregation.external_params, _a = aggregation.options, options = _a === void 0 ? [] : _a;
        var _b = (external_params || {}).links, links = _b === void 0 ? [] : _b;
        var optionsHolder = options;
        if (typeof optionsHolder === 'string') {
            optionsHolder = JSON.parse(optionsHolder);
        }
        setLinks(links);
        setOptions(optionsHolder);
    }, [aggregation]);
    var onAddClick = function (optionType) { return function () {
        if (disabled)
            return;
        var options = (0, lodash_1.get)(aggregation, 'options', []);
        var newOption = {};
        if (optionType === constants_1.optionTypes.default) {
            newOption = {
                text: '',
                id: (0, uuid_1.v4)(),
            };
        }
        else {
            newOption = createCustomOption(options, optionType);
        }
        aggregation.options = __spreadArray(__spreadArray([], options, true), [newOption], false);
        if ((0, placeholderValidationUtils_1.isFilledAttributes)(placeholder) && placeholder.resetInvalid) {
            placeholder.resetInvalid(placeholderValidationUtils_1.InvalidEnum.Attributes);
        }
        updateAggregations([aggregation]);
    }; };
    var onRemoveClick = function (index) {
        if (disabled)
            return;
        var _a = aggregation.options, options = _a === void 0 ? [] : _a, external_params = aggregation.external_params, has_relations = aggregation.has_relations;
        var optionId = options[index].id;
        if (has_relations) {
            var parentAggregation = aggregations.find(function (_a) {
                var id = _a.id;
                return id === aggregation.parent_id;
            });
            if (parentAggregation && parentAggregation.settings) {
                var newSettings_1 = parentAggregation.settings[aggregation.id];
                Object.keys(newSettings_1).forEach(function (key) {
                    var _a;
                    return (_a = newSettings_1[key]) === null || _a === void 0 ? void 0 : _a.forEach(function (item, index) {
                        if (item.id === optionId)
                            newSettings_1[key].splice(index, 1);
                    });
                });
                updateAggregations([parentAggregation]);
            }
        }
        if (external_params && external_params.links) {
            delete external_params.links[options[index].id];
        }
        options.splice(index, 1);
        updateAggregations([aggregation]);
        if ((0, placeholderValidationUtils_1.isFilledAttributes)(placeholder) && placeholder.resetInvalid) {
            placeholder.resetInvalid(placeholderValidationUtils_1.InvalidEnum.Attributes);
        }
    };
    var onTextChange = function (e, optionId) {
        var _a = aggregation.options, options = _a === void 0 ? [] : _a, parent_id = aggregation.parent_id;
        var newValue = e.target.value;
        var currentOption = options.find(function (_a) {
            var id = _a.id;
            return id === optionId;
        });
        if (currentOption)
            currentOption.text = newValue;
        var optionsHolder = options;
        if (typeof optionsHolder === 'string') {
            optionsHolder = JSON.parse(optionsHolder);
        }
        setOptions(optionsHolder);
        if (parent_id) {
            var parentAggregation = aggregations.find(function (_a) {
                var id = _a.id;
                return id === parent_id;
            });
            if (parentAggregation && parentAggregation.settings) {
                var newSettings_2 = parentAggregation.settings[aggregation.id];
                Object.keys(newSettings_2).forEach(function (key) {
                    var _a;
                    return (_a = newSettings_2[key]) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                        if (item.id === optionId)
                            item.text = newValue;
                    });
                });
                updateAggregations([parentAggregation]);
            }
        }
        updateAggregations([aggregation]);
    };
    var onExternalTextChange = function (e, index) {
        var _a;
        var value = e && e.target ? e.target.value : e;
        if (disabled) {
            return;
        }
        var _b = aggregation.options, options = _b === void 0 ? [] : _b, _c = aggregation.external_params, external_params = _c === void 0 ? {} : _c;
        var links = __assign(__assign({}, external_params.links), (_a = {}, _a[options[index].id] = value, _a));
        aggregation.external_params = __assign(__assign({}, external_params), { links: links });
        setLinks(links);
        onAggregationChange([aggregation]);
    };
    var onAnyOtherFieldChange = function (event, index) {
        if (event.target.checked) {
            onExternalTextChange(constants_1.defaultInputValues.any, index);
        }
        else {
            onExternalTextChange('', index);
        }
    };
    var onExternalParamsSwitch = function (checked) {
        aggregation.isReceived = checked;
        if (!checked) {
            aggregation.external_params = {};
        }
        onOptionChanged(aggregation);
    };
    var onSystemChooseHandler = function (value) {
        var chosenSystem = externalParamsCatalogue.find(function (system) { return system.id === value; }) || { id: '', name: '' };
        // TODO separate action for
        aggregation.external_params = {
            chosenSystem: {
                id: chosenSystem.id,
                name: chosenSystem.name,
            },
        };
        onOptionChanged(aggregation);
    };
    var onFieldChooseHandler = function (e, chosenSystemOptions) {
        var selectedOption = chosenSystemOptions.find(function (option) { return option.id === e; });
        var chosenField = { id: '', name: '' };
        if (selectedOption) {
            chosenField = {
                id: selectedOption.id,
                name: selectedOption.name,
            };
        }
        // TODO separate action for this
        aggregation.external_params = __assign(__assign({}, aggregation.external_params), { chosenField: chosenField });
        onOptionChanged(aggregation);
    };
    var getMaxOptionOrder = function (options, optionTextToReplace) {
        var maxOrder = 0;
        if (!options || !options.length) {
            return maxOrder;
        }
        options.forEach(function (option) {
            var optionText = (0, lodash_1.get)(option, 'text', '');
            var optionOrder = optionText.replace(optionTextToReplace, '');
            if (!optionOrder) {
                return;
            }
            var parsedOrder = parseInt(optionOrder, 10);
            if (parsedOrder > maxOrder) {
                maxOrder = parsedOrder;
            }
        });
        return maxOrder;
    };
    var createCustomOption = function (options, optionType) {
        var _a;
        var sameTypeOptions = options.filter(function (option) { return option && option[optionType]; });
        var maxOptionOrder = getMaxOptionOrder(sameTypeOptions, constants_1.defaultInputValues[optionType]);
        var optionText = constants_1.defaultInputValues[optionType];
        if (sameTypeOptions.length) {
            optionText += " ".concat(maxOptionOrder + 1);
        }
        return _a = {
                text: optionText,
                id: (0, uuid_1.v4)()
            },
            _a[optionType] = true,
            _a;
    };
    // TODO separate component
    var resolveRelationRows = function (index, item, disabled, isReceived, chosenSystemValues) {
        var externalTextareaValue = links[item.id] || null;
        var showSelects = chosenSystemValues && (chosenSystemValues.length > 1 || (chosenSystemValues.length === 1 && chosenSystemValues[0] !== ''));
        var isTextAnyOtherField = externalTextareaValue === constants_1.defaultInputValues.any;
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'option' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: 'option__index' }, { children: [index + 1, "."] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'textarea__wrapper' }, { children: [isReceived &&
                            (0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [!chosenSystemValues || !showSelects ?
                                        ((0, jsx_runtime_1.jsx)(TextArea, { placeholder: '\u041E\u0436\u0438\u0434\u0430\u0435\u043C\u043E\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0438\u0437 \u0441\u0438\u0441\u0442\u0435\u043C\u044B', className: 'placeholder-description panel__textarea option__textarea', disabled: disabled || isTextAnyOtherField, value: externalTextareaValue, onChange: function (e) { return onExternalTextChange(e, index); } }))
                                        : ((0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ disabled: disabled || isTextAnyOtherField, className: 'external-system__select', value: externalTextareaValue, onChange: function (e) { return onExternalTextChange(e, index); } }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'empty-option' }, { children: "\u041F\u0443\u0441\u0442\u043E\u0435 \u043F\u043E\u043B\u0435" })) })), chosenSystemValues.map(function (value) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: value }, { children: value }), value)); })] }))), (0, jsx_runtime_1.jsx)("div", { className: 'dependency-pseudo' })] }), (0, jsx_runtime_1.jsx)(TextArea, { placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435', className: [
                                'placeholder-description',
                                'panel__textarea',
                                'option__textarea',
                                isReceived && 'option_dependant',
                            ].join(' '), disabled: disabled || item.userInput || item.emptyValue, value: item.text, onChange: function (e) { return onTextChange(e, item.id); } }), isReceived && !disabled && (0, jsx_runtime_1.jsx)(antd_1.Checkbox, __assign({ className: 'separate-checkbox option__any-other-checkbox', checked: isTextAnyOtherField, onChange: function (e) { return onAnyOtherFieldChange(e, index); } }, { children: constants_1.defaultInputValues.any }))] })), !disabled &&
                    (0, jsx_runtime_1.jsx)("div", __assign({ className: 'option__remove-button' }, { children: (0, jsx_runtime_1.jsx)("div", { className: 'remove__icon', onClick: function () { return onRemoveClick(index); } }) }))] }), "relation-".concat(item.id)));
    };
    var isReceived = aggregation.isReceived, external_params = aggregation.external_params;
    var chosenSystem = {};
    var chosenField = {};
    var chosenSystemValuesObject = {};
    if (external_params) {
        chosenSystem = external_params.chosenSystem || {};
        chosenField = external_params.chosenField || {};
    }
    var chosenSystemParams = externalParamsCatalogue.length && chosenSystem
        ? externalParamsCatalogue.find(function (system) { return system.id === chosenSystem['id']; })
        : null;
    var chosenSystemOptions = chosenSystemParams && chosenSystemParams.attribute_ids;
    if (chosenSystemOptions && chosenField) {
        chosenSystemValuesObject = chosenSystemOptions.find(function (option) { return option.id === chosenField['id']; });
    }
    var chosenSystemValues = chosenSystemValuesObject ? chosenSystemValuesObject['values'] : [];
    var shouldRenderOptions = !isReceived || (isReceived && chosenField && chosenField['id']);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'cascader-options' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: 'external-params-switch' }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Switch, { size: 'small', checked: isReceived, onChange: function (e) { return onExternalParamsSwitch(e); }, disabled: disabled }), (0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: 'related_switch' }, { children: "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u044C \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B \u0438\u0437 \u0432\u043D\u0435\u0448\u043D\u0435\u0439 \u0441\u0438\u0441\u0442\u0435\u043C\u044B" }))] })), isReceived &&
                (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ className: 'external-system__select', value: (chosenSystem && chosenSystem['name']) || null, onChange: onSystemChooseHandler, disabled: disabled }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'empty-option' }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0438\u0441\u0442\u0435\u043C\u0443 \u0434\u043B\u044F \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0438" })) })), externalParamsCatalogue.map(function (catalogue, index) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: catalogue.id }, { children: catalogue.name }), "".concat(catalogue.id + index + externalParamsCatalogue.length, "_dd"))); })] })), chosenSystemOptions && chosenSystemOptions.length > 0 && isReceived &&
                (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ showSearch: true, className: 'external-system__select', value: chosenField['name'] || null, onChange: function (e) { return onFieldChooseHandler(e, chosenSystemOptions); }, disabled: disabled, filterOption: placeholderValueHelpers_1.handleFilterOption }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'empty-option' }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u043C\u044F \u043F\u043E\u043B\u044F" })) })), chosenSystemOptions.map(function (catalogue, index) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: catalogue.id }, { children: catalogue.name }), "".concat(catalogue.id + index + chosenSystemOptions.length, "_dd"))); })] })), shouldRenderOptions && ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "placeholder_option_items" }, { children: [options.length > 0 && options.map(function (item, index) { return (resolveRelationRows(index, item, disabled, isReceived, chosenSystemValues)); }), !disabled &&
                            (0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ onClick: onAddClick(constants_1.optionTypes.default), "aria-disabled": disabled, className: 'new-option-button' }, { children: [(0, jsx_runtime_1.jsx)("div", { className: 'plus-icon' }), (0, jsx_runtime_1.jsx)("span", { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0432\u0430\u0440\u0438\u0430\u043D\u0442" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ onClick: onAddClick(constants_1.optionTypes.emptyValue), "aria-disabled": disabled, className: 'new-option-button' }, { children: [(0, jsx_runtime_1.jsx)("div", { className: 'plus-icon' }), (0, jsx_runtime_1.jsx)("span", { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0443\u0441\u0442\u043E\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ onClick: onAddClick(constants_1.optionTypes.userInput), "aria-disabled": disabled, className: 'new-option-button' }, { children: [(0, jsx_runtime_1.jsx)("div", { className: 'plus-icon' }), (0, jsx_runtime_1.jsx)("span", { children: "\u0412\u0432\u043E\u0434 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u043C" })] })), options.length < 2 && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "note warning" }, { children: "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435! \u041D\u0430\u0441\u0442\u0440\u043E\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u043E\u0434\u0438\u043D \u0432\u0430\u0440\u0438\u0430\u043D\u0442" })) }))] })] })) }))] })));
};
exports.TextOptions = TextOptions;
