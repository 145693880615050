"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.VERTICAL_ALIGNS = exports.CELL_BORDERS = exports.CELL_BUTTON_SETTINGS = exports.VerticalAligns = exports.CellBorders = void 0;
var CellBorders;
(function (CellBorders) {
    CellBorders["left"] = "borderLeft";
    CellBorders["right"] = "borderRight";
    CellBorders["top"] = "borderTop";
    CellBorders["bottom"] = "borderBottom";
})(CellBorders = exports.CellBorders || (exports.CellBorders = {}));
var VerticalAligns;
(function (VerticalAligns) {
    VerticalAligns["bottom"] = "bottom";
    VerticalAligns["middle"] = "middle";
    VerticalAligns["top"] = "top";
})(VerticalAligns = exports.VerticalAligns || (exports.VerticalAligns = {}));
exports.CELL_BUTTON_SETTINGS = (_a = {},
    _a[CellBorders.left] = {
        hint: 'Выделить границы слева',
        iconName: CellBorders.left,
    },
    _a[CellBorders.right] = {
        hint: 'Выделить границы справа',
        iconName: CellBorders.right,
    },
    _a[CellBorders.top] = {
        hint: 'Выделить границы сверху',
        iconName: CellBorders.top,
    },
    _a[CellBorders.bottom] = {
        hint: 'Выделить границы снизу',
        iconName: CellBorders.bottom,
    },
    _a[VerticalAligns.bottom] = {
        hint: 'Вертикальное выравнивание по нижнему краю',
        iconName: 'verticalAlignBottom',
    },
    _a[VerticalAligns.middle] = {
        hint: 'Вертикальное выравнивание по центру',
        iconName: 'verticalAlignCenter',
    },
    _a[VerticalAligns.top] = {
        hint: 'Вертикальное выравнивание по верхнему краю',
        iconName: 'verticalAlignTop',
    },
    _a.borderAll = {
        hint: 'Выделить все границы',
        iconName: 'borderAll',
    },
    _a.borderClear = {
        hint: 'Очистить все границы',
        iconName: 'borderClear',
    },
    _a);
exports.CELL_BORDERS = Object.values(CellBorders);
exports.VERTICAL_ALIGNS = Object.values(VerticalAligns);
